import "bootstrap-material-design";
import "../stylesheets/application";
import "jquery";
import Chart from "chart.js";
import "packs/main-menu";
import Headroom from "headroom.js";
import Modal from 'bootstrap/js/src/modal';

/* eslint-disable no-undef,no-underscore-dangle */
$.fn.modal = Modal._jQueryInterface;

$(function() {
    const bottomMenu = $('.bottom-menu');
    new Headroom(bottomMenu[0]).init();
    const jumpToTop = $('.jump-to-top');
    new Headroom(jumpToTop[0]).init();

    const latestNoticeId = $('body').data('latestNoticeId');
    if (latestNoticeId) {
        const prev = localStorage.getItem('latestNoticeId');
        if (prev) {
            if (parseInt(prev) < latestNoticeId) {
                $('.icon-badge.notice-badge').removeClass('none');
            }
        } else {
            localStorage.setItem('latestNoticeId', latestNoticeId);
        }
    }

    window.jumpToTop = () => {
        $('body,html').animate({scrollTop: 0});
    }
})

$(window).on('load', () => {
    const mainContents = $('.main-contents');
    const mainContentsHeight = mainContents.outerHeight();
    const footerHeight = $('footer').outerHeight();
    const windowHeight = window.innerHeight;
    if (mainContentsHeight + footerHeight < windowHeight) {
        mainContents.outerHeight(windowHeight - footerHeight);
    }
});
