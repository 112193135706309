$(function() {
    const mainMenuBackground = $('header .main-menu-background');
    const mainMenu = $('header .main-menu-base');
    const searchMenu = $('header .search-menu-base');
    const searchInput = $('header .search-input');
    const scrollable = mainMenu.find('.scrollable');
    const row = mainMenu.find('.scrollable > .row');
    const col = mainMenu.find('.scrollable > .row > .col-12');
    const navbarHeight = $('header nav.navbar').height();


    window.openMainMenu = () => {
        mainMenuBackground.removeClass('d-none');
        mainMenu.removeClass('d-none');

        setTimeout(() => {
            const windowHeight = window.innerHeight
            const menuHeight = col.height();
            row.height(menuHeight);

            if (windowHeight < navbarHeight + menuHeight) {
                scrollable.height(windowHeight - navbarHeight);
            } else {
                scrollable.height(menuHeight);
            }
        }, 100);
        return false;
    }

    window.closeMainMenu = (event) => {
        if (event && event.target !== mainMenu[0]) {
            return true;
        }
        mainMenuBackground.addClass('d-none');
        mainMenu.addClass('d-none');
        return false;
    }

    window.openSearchMenu = () => {
        searchMenu.removeClass('d-none');
        setTimeout(() => {
            searchInput.focus().select();
        }, 0);
        return false;
    }

    window.closeSearchMenu = () => {
        searchMenu.addClass('d-none');
        return false;
    }
})
